import React, { createContext, FC, useEffect, useRef, useState } from "react";
import Navigation, { closeSubMenuHandleRef } from "../navigation";
import styles from "./header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPreferenceRequest } from "../../slice/preferenceSlice";
import TGIcon from "../../../../shared/tg-icon";
import Login from "../../../../shared/login-widget/index";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import ContryLanguageWidget from "../country-language-widget";
import Notifications from "../../component/notifications";
import { countryDataRequest } from "../../slice/header/countrySlice";
import { fetchOrSaveLocalizeValue } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
// To animate the hide and show the header.
import gsap from "gsap";
import { RootState } from "../../slice/RootReducer";
import MiniProfile from "../../../../shared/mini-profile";
import { LoginSocialFacebook } from "reactjs-social-login";
import { constant } from "../../../../config/constant";
import { useLocation } from "react-router-dom";
import { setIsPanelClosed, setUserName } from "../../slice/loginPanelSlice";
import { Help } from "../../component/Help";

export const FunctionContext = createContext(() => {});

gsap.registerPlugin();
const Header: FC = () => {
  /// Diapatching the call for fetching preference to set country and language dropdown
  const dispatch = useDispatch();
  let location = useLocation();
  const headerRef = useRef(null);
  const helpRef = useRef(null);
  const isMobileView: boolean = useScreenSize().deviceSize?.width < 1081;
  const isDesktopView = useScreenSize().deviceSize?.width < 768;
  const countryData = useSelector(
    (state: RootState) => state?.osciReducer?.country?.CountryDetails
  );
  const { i18n } = useTranslation();

  const { isPanelClosed, userName } = useSelector((state: RootState) => state?.osciReducer?.loginPanel);

  const profileDetails = useSelector((state: RootState) => state?.osciReducer?.otpData?.otpDetails);

  const [isheader, setIsHeader] = useState(false);

  const [miniProfileShow, setMiniProfileShow] = useState(false);

  const headerScrollRef = useRef(null);

  const [lastScroll, setLastScroll] = useState(0);

  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const [isHelpClicked, setIsHelpClicked] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const headerScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(headerScroll - lastScroll) > 10) {
        // to check the scroll to prevent minor jumps
        if (headerScroll > 300 && headerScroll > lastScroll) {
          if (headerScroll > 250) {
            //  to check the header to pass the some distance to start the animation
            setIsHeader(true);
          }
          gsap.to(headerScrollRef.current, { y: "-100%", duration: 0.25 });
        }
        if (headerScroll < 300 || headerScroll < lastScroll) {
          gsap.to(headerScrollRef.current, { y: "0%", duration: 0.25 });
          if (headerScroll < 300) {
            //  header sets to relative when screen reaches 300px or less
            setIsHeader(false);
          }
        }
        setLastScroll(headerScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);

  useEffect(() => {
    if (countryData?.length > 0 && i18n?.language?.includes("-")) {
      fetchOrSaveLocalizeValue(
        countryData,
        i18n?.language?.toLowerCase().split("-"),
        false
      );
    }
  }, [countryData, i18n?.language]);

  useEffect(() => {
    if (miniProfileShow) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else if (!miniProfileShow) {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
  }, [miniProfileShow]);

  //Login section methods
  const handlePanelClose = (callback?: Function): void => {
    dispatch(setIsPanelClosed(false));
    if (callback) {
      callback();
    }
  };

  // Toggle help section visibility
  const handleHelpClick = (): void => {
    if (isHelpClicked) {
      setIsHelpClicked(false);
      setIsHelpOpen(false);
    } else {
      setIsHelpClicked(true);
      setIsHelpOpen(true);
    }
  };

  // Handle Help Hover
  const handleHelpHover = (isHovering: boolean): void => {
    if (!isHelpClicked) {
      setIsHelpOpen(isHovering);
    }
  };

  // Handle hover outside the help component
  const handleMouseLeaveHelp = () => {
    if (isHelpOpen) {
      setIsHelpOpen(false);
      setIsHelpClicked(false);
    }
  };

  // Adding a ref to Navigation component to handle sub menu close from header
  const navRef = useRef<closeSubMenuHandleRef>(null);

  const handleSubMenuClose = () => {
    if (navRef.current) {
      navRef.current.closeSubMenu();
    }
  };

  const handleNavigation = () => {
    window.location.href = `/${i18n.language}/signup`;
  };
  const loginUserName = (name: string): void => {
    dispatch(setUserName(name));
  };
  const handlePanelShow = (): void => {
    dispatch(setIsPanelClosed(!isPanelClosed));
  };

  //API calls
  useEffect(() => {
    const reset = sessionStorage.getItem("reset");
    if (reset) {
      sessionStorage.removeItem("reset");
    }
    dispatch(getPreferenceRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(countryDataRequest());
  }, [dispatch]);

  useEffect(() => {
    const memberId = sessionStorage.getItem("memberId");
    const token = sessionStorage.getItem("accesstoken");
    const customerId = sessionStorage.getItem("customerID");
    const fullName = sessionStorage.getItem("FullName");
    const firstName = sessionStorage.getItem("FirstName");
    if (
      (memberId && token) ||
      (customerId && fullName) ||
      profileDetails?.firstName
    ) {
      const profileUserName = sessionStorage.getItem("FullName");
      if (firstName) {
        dispatch(setUserName(firstName));
      } else if (profileUserName || profileDetails?.firstName) {
        dispatch(setUserName(profileUserName || profileDetails?.firstName));
      }
    }
  }, [
    sessionStorage.getItem("memberId"),
    sessionStorage.getItem("customerID"),
    sessionStorage.getItem("FullName"),
    sessionStorage.getItem("FirstName"),
    profileDetails,
  ]);

  const displayProfileInfo = () => {
    let name = userName.split(" ");
    let displayName = name[1] ? name[1] : name[0];
    sessionStorage.setItem(
      "profileName",
      displayName?.charAt(0).toUpperCase() + displayName?.slice(1).toLowerCase()
    );
    return (
      displayName?.charAt(0).toUpperCase() + displayName?.slice(1).toLowerCase()
    );
  };

  const handleMiniProfile = () => {
    setMiniProfileShow((isOpen) => !isOpen);
  };

  const closeMiniProfile = () => {
    setMiniProfileShow(false);
  };

  const { appIdFbCRM } = constant;

  return (
    <div
      className={isheader ? styles.animatedHeader : styles.staticHeader}
      ref={headerScrollRef}
    >
      <div
        className={`${styles.headerContainer} global-content-padding`}
        ref={headerRef}
        style={{
          borderBottom:
            location.pathname.includes("my-trips") ||
            location.pathname.includes("past-trips")
              ? "1px solid #F2F2FA"
              : "",
        }}
      >
        {!isMobileView && (
          <div
            onMouseEnter={handleSubMenuClose}
            className={styles["header-wrapper"]}
          >
            {typeof headerRef?.current !== null && (
              <ContryLanguageWidget ref={headerRef} />
            )}
            <div
              onClick={() => handleHelpClick()}
              onMouseEnter={() => handleHelpHover(true)}
              onMouseLeave={() => handleHelpHover(false)}
              className={styles["help-text"]}
              role="button"
              tabIndex={0}
            >
              <TGIcon icon="help-icon" fillColor="" size="16" />
              <span>{t("label_header_help")}</span>
            </div>
            <div className={styles["sign-in-text"]} role="button" tabIndex={0}>
              <span>
                {!userName || userName.includes("undefined") ? (
                  <span
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    onClick={handlePanelShow}
                  >
                    <TGIcon icon="sign-in-icon" fillColor="" size="16" />
                    <span style={{ marginLeft: "10px" }}>
                      {t("label_signin_header")}/
                    </span>
                  </span>
                ) : (
                  <span tabIndex={0} onClick={handleMiniProfile}>
                    <TGIcon icon="sign-in-icon" fillColor="" size="16" />
                    {!userName?.includes("undefined") || userName !== undefined
                      ? " " + displayProfileInfo()
                      : t("label_signin_header")}
                    {userName ? (
                      <TGIcon icon="chevron-down-icon" fillColor="" size="16" />
                    ) : (
                      ""
                    )}
                  </span>
                )}
                {userName ? (
                  ""
                ) : (
                  <span
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    onClick={handleNavigation}
                  >
                    <span>{t("label_join_now")} </span>
                  </span>
                )}
              </span>
            </div>
          </div>
        )}
        <Navigation
          isHelpClicked={isHelpClicked}
          ref={navRef}
          userName={userName}
          miniProfileClose={closeMiniProfile}
        />
        {isPanelClosed && (
          <Login
            onHidePanel={handlePanelClose}
            loginName={loginUserName}
            handlePanelShow={handlePanelShow}
          />
        )}
        {miniProfileShow && (
          <FunctionContext.Provider value={closeMiniProfile}>
            <MiniProfile />
          </FunctionContext.Provider>
        )}
        {isHelpOpen && (
          <div ref={helpRef} onMouseLeave={handleMouseLeaveHelp}>
            <Help />
          </div>
        )}
      </div>
      {!(
        location.pathname.includes("signup") ||
        location.pathname.includes("resetpassword") ||
        location.pathname.includes("my-trips") ||
        location.pathname.includes("past-trips")
      ) && <Notifications />}
      <LoginSocialFacebook
        appId={appIdFbCRM}
        className=""
        fieldsProfile=""
        onResolve={() => {}}
        onReject={() => {}}
      ></LoginSocialFacebook>
    </div>
  );
};

export default Header;
